import React, { useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { valueData } from '../../data/valueData';

import './Projects.css'
import SingleProject from './SingleProject/SingleProject';

function Projects() {

    const { theme } = useContext(ThemeContext);

    return (
        <>
            <div className="projects" id="projects" style={{ backgroundColor: theme.secondary }}>
                <div className="projects--header">
                    <h1 style={{ color: theme.primary }}>Our Core Values</h1>
                </div>
                <div className="projects--body">
                    <div className="projects--bodyContainer">
                        {valueData && valueData.slice(0, 5).map(project => (
                            <SingleProject
                                theme={theme}
                                key={project._id}
                                name={project.valueHead}
                                desc={project.valueBody}
                                tags={project.valueFoot}
                                code={project.code}
                                demo={project.demo}
                                image={project.valueImage}
                            />
                        ))}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Projects
