export const achievementData = [
    {
        _id : 1,
        achievementHead : 'Top School Award',
        achievementBody : '2020-2021 Top Matriculation School in Coimbatore District.',
        achievementFoot : 'Aug 20, 2021',
        achievementImage : 'images/Award.jpg'
    },
    {
        _id : 2,
        achievementHead : 'Best Coaching Award',
        achievementBody : '2020-2021 Best Coach Award School in Coimbatore District.',
        achievementFoot : 'Aug 20, 2021',
        achievementImage : 'images/Award.jpg'
    },
    {
        _id : 3,
        achievementHead : 'Overall Performance Award',
        achievementBody : '2019-2020 Top CBSC School in Coimbatore District.',
        achievementFoot : 'Aug 20, 2020',
        achievementImage : 'images/Award.jpg'
    },
    {
        _id : 4,
        achievementHead : 'Best Environental Award',
        achievementBody : 'Best Environmental Structure Award to Maintaining Good Infrastructure for Student.',
        achievementFoot : 'Aug 20, 2019',
        achievementImage : 'images/Award.jpg'
    }
]

