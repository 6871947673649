import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
// import five from '../assets/svg/projects/five.svg'
// import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
// import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'LEARNING',
        projectDesc: 'We believe learning and evolving  is essential to creating change. Not only for the children, teachers and parents, but for our staff, our Board of Directors and our world-changing supporters!',
        // tags: ['KMHSS','SS'],
        tags: 'KMHSS',
        image: one
    },
    {
        id: 2,
        projectName: 'EMPOWERMENT',
        projectDesc: 'Empowerment is an essential pillar of our work. Our model empowers all stakeholders, including children & youth,  to become education change agents in our society.',
        tags: 'KMHSS2',
        image: two
    },
    {
        id: 3,
        projectName: 'TRANSPARENCY',
        projectDesc: 'We focus on the quality of our work and the honesty in our relationships. We commit to measuring our effectiveness, continually improving our programs.',
        tags: 'KMHSS',
        image: three
    },
    {
        id: 4,
        projectName: 'ACCOUNTABILITY',
        projectDesc: 'We hold ourselves and all stakeholders accountable. The children deserve nothing less and without accountability, generational poverty is sure to continue.',
        tags: 'KMHSS4',
        image: four
    },
    {
        id: 5,
        projectName: 'COMMUNITY',
        projectDesc: 'We partner with communities to create community. Our model brings together government, community leaders, parents, teachers and global citizens, working together to change the status quo for children.',
        tags: 'KMHSS',
        image: seven
    }
]