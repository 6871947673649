export const educationData = [
    {
        _id: 1,        
        educationHead: '2002-Present',
        educationBody: 'Higher Secondary Education (Class XI to XII).',
        educationFoot: 'This stage of education is provided for children who has age of above 16 years.',//They can pursue Arts, Commerce, Science (medical & nonmedical).
        educationImage: 'eduimg'
    },
    {
        _id: 2,        
        educationHead: '2000-Present',
        educationBody: 'Secondary Education (Class IX to X).',
        educationFoot: 'This stage of education is provided for children who has age of above 14 years.',//Subjects in this stage are Mathematics, Science, Social Science, English, Tamil or the regional language of a particular state.
        educationImage: 'eduimg'
    },
    {
        _id: 3,        
        educationHead: '1998-Present',
        educationBody: 'Middle Education (Class VI to VIII).',
        educationFoot: 'This stage of education is provided for children who has age of above 12 years.',
        educationImage: 'eduimg'
    },
    {
        _id: 4,        
        educationHead: '1998-Present',
        educationBody: 'Primary Education (Class I to V).',
        educationFoot: 'This stage of education is provided for children who has age of above 5 years.',// The subjects taught at the primary school include science, geography, history, math and other social sciences.
        educationImage: 'eduimg'
    },
    {
        _id: 5,        
        educationHead: '2005-Present',
        educationBody: 'Pre Primary Education (Pre-KG, LKG, UKG).',
        educationFoot: 'This stage of education is provided for children who has age of above 2 years.',
        educationImage: 'eduimg'
    },
]