import React, { useContext } from 'react';
import Marquee from "react-fast-marquee";

import './Skills.css'

import { ThemeContext } from '../../contexts/ThemeContext';
import { recentData } from '../../data/recentData';

function Skills() {

    const { theme } = useContext(ThemeContext);

    const skillBoxStyle = {
        backgroundColor: theme.primary400,
        boxShadow: `0px 0px 30px ${theme.primary30}`
    }

    return (
        <div className="skills" style={{ backgroundColor: theme.secondary }}>
            <div className="skillsHeader">
                <h2 style={{ color: theme.primary }}>Recent and Upcoming Updates</h2>
            </div>
            <div className="skillsContainer">
                <div className="skill--scroll">
                    <Marquee
                        gradient={false}
                        speed={80}
                        pauseOnHover={true}
                        pauseOnClick={true}
                        delay={0}
                        play={true}
                        direction="left"
                    >
                        {recentData && recentData.map((recent, id) => (
                            <div className="skill--box" key={id} style={skillBoxStyle}>
                                <div
                                    className='review--content'
                                    style={{
                                        backgroundColor:
                                            theme.secondary,
                                        color: theme.tertiary,
                                    }}
                                >
                                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{recent.recentHead}</p>
                                    <h3>{recent.recentBody}</h3>
                                    <h4 style={{ fontFamily: 'monospace' }}>{recent.recentFoot}</h4>
                                </div>
                            </div>
                        ))}
                    </Marquee>
                </div>
            </div>
        </div>
    )
}

export default Skills