import React, { useContext } from 'react';

import './Achievement.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { achievementData } from '../../data/achievementData'
import AchievementCard from './AchievementCard';

function Achievement() {

    const { theme } = useContext(ThemeContext);
    return (
        <>
            <div className="achievement" id="achievement" style={{ backgroundColor: theme.secondary }}>
                <div className="achievement-body">
                    <h1 style={{ color: theme.primary }}>Achievements</h1>
                    <h4 style={{ color: theme.tertiary }}>Putting quantified achievements on this industry to stand in best place.</h4>
                </div>
                <div className="achievement-cards">
                    {achievementData && achievementData.map(achieve => (
                        <AchievementCard
                            key={achieve._id}
                            achievementHead={achieve.achievementHead}
                            achievementBody={achieve.achievementBody}
                            achievementFoot={achieve.achievementFoot}
                            achievementImage={achieve.achievementImage} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default Achievement