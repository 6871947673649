import React, { useContext } from 'react'
import './Footer.css'
import { ThemeContext } from '../../contexts/ThemeContext'
import { headerData } from '../../data/headerData'

function Footer() {

    const { theme } = useContext(ThemeContext)

    return (
        <div className="footer" style={{ backgroundColor: theme.secondary }}>
            <p style={{ color: theme.tertiary }}>
                <a style={{ color: theme.primary }} href='https://kandasamymhss.com/'>{headerData.title}</a>
                <span style={{ color: theme.primary, margin: '0 0.5rem -1rem 0.5rem' }}>
                    ❤
                </span>
                &copy; {headerData.copyrights}
                by <a style={{ color: theme.primary }} href='http://budget2buy.com/'>{headerData.company}</a>
            </p>
        </div>
    )
}

export default Footer