/* eslint-disable */
import { BiShoppingBag, BiPencil,BiSwim,BiPaint,BiFootball,BiTrip,BiBusSchool } from "react-icons/bi";
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { AiOutlineMail, AiFillAudio } from "react-icons/ai";
import { FaInternetExplorer,  FaChalkboardTeacher, FaCameraRetro, FaPinterest, FaVideo, FaTabletAlt, FaRegNewspaper,FaSwimmer, FaSkating,FaVolleyballBall, FaBasketballBall, FaJediOrder } from "react-icons/fa"; 
import {  BsMusicNoteList} from "react-icons/bs"; 
import {  GrYoga, GrSwim} from "react-icons/gr"; 
import {  GiHockey} from "react-icons/gi";
import {  SiJordan,SiGoogleclassroom} from "react-icons/si"; 
import {  TbKarate} from "react-icons/tb"; 

export const activityData = [
    {
        _id: 1,
        activityBody: 'Dance',
        activityImage: <SiJordan/>
    },
    {
        _id: 2,
        activityBody: 'Music',
        activityImage: <BsMusicNoteList /> 
    },
    {
        _id: 3,
        activityBody: 'Yoga',
        activityImage: <GrYoga />
    },
    {
        _id: 4,
        activityBody: 'Swimming',
        activityImage: <BiSwim />
    },
    {
        _id: 5,
        activityBody: 'Drawing',
        activityImage: <BiPaint />
    },   
    {
        _id: 6,
        activityBody: 'Skating',
        activityImage: <FaSkating />
    },
    {
        _id: 7,
        activityBody: 'Hockey',
        activityImage: <GiHockey />
    },
    {
        _id: 8,
        activityBody: 'Football',
        activityImage: <BiFootball />
    }, 
    {
        _id: 9,
        activityBody: 'Volley Ball',
        activityImage: <FaVolleyballBall />
    },
    {
        _id: 10,
        activityBody: 'Karate', 
        activityImage: <TbKarate />
    },
    {
        _id: 11,
        activityBody: 'Field Trip',
        activityImage: <BiTrip />
    },
    {
        _id: 12,
        activityBody: 'Smart Class',
        activityImage: <SiGoogleclassroom />
    },
    {
        _id: 13,
        activityBody: 'Transport',
        activityImage: <BiBusSchool />
    }
]

