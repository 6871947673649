export const blogData = [
    {
        _id:1,
        blogName: "Right To Learn",
        blogTitle: "My vision is for children to be able to exercise their right to learn",
        blogDesc: "More extreme weather events are fuelling humanitarian crises and disrupting children’s access to education.",
        blogDate: "Jan 31, 2023",
        blogImage: "images/blogs/blog2.jpg",
        blogURL: "https://bold.expert/my-vision-is-for-children-to-be-able-to-exercise-their-right-to-learn/"        
    },
    {
        _id:2,
        blogName: "Learning Interventions",
        blogTitle: "How learning interventions can help children who are out of school",
        blogDesc: "The guests share caring, respectful approaches and innovative solutions that can offer new opportunities for the most vulnerable children around the world.",
        blogDate: "Jan 24, 2023",
        blogImage: "images/blogs/blog4.jpg",
        blogURL: "https://bold.expert/how-learning-interventions-can-help-children-who-are-out-of-school/"        
    },
    {
        _id:3,
        blogName: "Motivates Students",
        blogTitle: "What motivates students to learn?",
        blogDesc: "We wanted to identify the factors that are most closely related to academic motivation.",
        blogDate: "Sep 15, 2022",
        blogImage: "images/blogs/blog5.jpg",
        blogURL: "https://bold.expert/what-motivates-students-to-learn/"               
    },
    {
        _id:4,
        blogName: "25th Annual Day",
        blogTitle: "25th ANNUAL DAY CELEBRATION",
        blogDesc: "Here we will celebrate 25th Silver Jubilee Annual Day of KMHSS school.",
        blogDate: "Jan 29, 2023",
        blogImage: "images/blogs/blog1.jpg",
        blogURL: "http://kandasamymhss.com/"        
    },
    {
        _id:5,
        blogName: "Foundational Learning",
        blogTitle: "All children should have equal access to joyful, foundational learning",
        blogDesc: "The challenges facing young children today go beyond anything a single individual or organization can accomplish alone.",
        blogDate: "Nov 17, 2022",
        blogImage: "images/blogs/blog6.jpg",
        blogURL: "https://bold.expert/all-children-should-have-equal-access-to-joyful-foundational-learning/"               
    },
    {
        _id: 6,
        blogName: "Protecting Children",
        blogTitle: "Protecting children's right to play",
        blogDesc: "Play has a positive effect on all kinds of learning.",
        blogDate: "Sep 22, 2022",
        blogImage: "images/blogs/blog3.jpg",
        blogURL: "https://bold.expert/protecting-childrens-right-to-play/"    
    },
    
]