import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Fade from 'react-reveal/Fade';

import placeholder from '../../../assets/png/placeholder.png';
import './SingleProject.css';

import one from '../../../assets/svg/projects/one.svg'
import two from '../../../assets/svg/projects/two.svg'
import three from '../../../assets/svg/projects/three.svg'
import four from '../../../assets/svg/projects/four.svg'
import five from '../../../assets/svg/projects/five.svg'
import six from '../../../assets/svg/projects/six.svg'
import seven from '../../../assets/svg/projects/seven.svg'
import eight from '../../../assets/svg/projects/eight.svg'

function SingleProject({ id, name, desc, tags, code, demo, image, theme }) {
    const useStyles = makeStyles((t) => ({
        iconBtn: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: 50,
            border: `2px solid ${theme.tertiary}`,
            color: theme.tertiary,
            transition: 'all 0.2s',
            '&:hover': {
                backgroundColor: theme.secondary,
                color: theme.primary,
                transform: 'scale(1.1)',
                border: `2px solid ${theme.secondary}`,
            },
        },
        icon: {
            fontSize: '1.1rem',
            transition: 'all 0.2s',
            '&:hover': {},
        },
    }));

    const classes = useStyles();

    return (
        <Fade bottom>
            <div
                key={id}
                className='singleProject'
                style={{ backgroundColor: theme.primary400 }}
            >
                <div className='projectContent'>
                    <h2
                        id={name.replace(' ', '-').toLowerCase()}
                        style={{ color: theme.tertiary }}
                    >
                        {name}
                    </h2>

                    {/* <img src={image ? image : placeholder} alt={name} /> */}
                    {image && image === 'one' && (
                        <img src={one ? one : placeholder} alt={name} />
                    )}
                    {image && image === 'two' && (
                        <img src={two ? two : placeholder} alt={name} />
                    )}
                    {image && image === 'three' && (
                        <img src={three ? three : placeholder} alt={name} />
                    )}
                    {image && image === 'four' && (
                        <img src={four ? four : placeholder} alt={name} />
                    )}
                    {image && image === 'five' && (
                        <img src={five ? five : placeholder} alt={name} />
                    )}
                    {image && image === 'six' && (
                        <img src={six ? six : placeholder} alt={name} />
                    )}
                    {image && image === 'seven' && (
                        <img src={seven ? seven : placeholder} alt={name} />
                    )}
                    {image && image === 'eight' && (
                        <img src={eight ? eight : placeholder} alt={name} />
                    )}

                    <div className='project--showcaseBtn'>
                        <a
                            href={demo}
                            target='_blank'
                            rel='noreferrer'
                            className={classes.iconBtn}
                            aria-labelledby={`${name
                                .replace(' ', '-')
                                .toLowerCase()} ${name
                                    .replace(' ', '-')
                                    .toLowerCase()}-demo`}
                        >
                            <FaArrowRight
                                id={`${name
                                    .replace(' ', '-')
                                    .toLowerCase()}-demo`}
                                className={classes.icon}
                                aria-label='Demo'
                            />
                        </a>
                        <a
                            href={code}
                            target='_blank'
                            rel='noreferrer'
                            className={classes.iconBtn}
                            aria-labelledby={`${name
                                .replace(' ', '-')
                                .toLowerCase()} ${name
                                    .replace(' ', '-')
                                    .toLowerCase()}-code`}
                        >
                            <FaArrowLeft
                                id={`${name
                                    .replace(' ', '-')
                                    .toLowerCase()}-code`}
                                className={classes.icon}
                                aria-label='Code'
                            />
                        </a>
                    </div>
                </div>
                <p
                    className='project--desc'
                    style={{
                        background: theme.secondary,
                        color: theme.tertiary,
                    }}
                >
                    {desc}
                </p>
                <div
                    className='project--lang'
                    style={{
                        background: theme.secondary,
                        color: theme.tertiary80,
                    }}
                >
                    <span>{tags}</span>
                </div>
            </div>
        </Fade>
    );
}

export default SingleProject;
