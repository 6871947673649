import React, { useContext } from 'react';

import { ThemeContext } from '../../contexts/ThemeContext';

import './Education.css'
import EducationCard from './EducationCard';

import { educationData } from '../../data/educationData'

function Education() {

    const { theme } = useContext(ThemeContext);
    return (
        <div className="education" id="curriculum" style={{ backgroundColor: theme.secondary }}>

            <div className="education-body">
                <div className="education-description">
                    <h1 style={{ color: theme.primary }}>Curriculum Standards</h1>
                    {educationData && educationData.map(edu => (
                        <EducationCard
                            key={edu._id}
                            eduHead={edu.educationHead}
                            eduBody={edu.educationBody}
                            eduFoot={edu.educationFoot}
                        />
                    ))}
                </div>
                <div className="education-image">
                    <img src={theme.eduimg} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Education
