import resume from '../assets/pdf/resume.pdf'

export const headerData = {
    company:'Budget2Buy Solutions',
    copyrights:'2023 / All Rights Reserved. Designed and Hosted',
    title: 'KMHSS',
    name: 'Kandasamy Matriculation Higher Secondary School',
    welcome: "Welcome to",
    desciption:"Our school continuously encourages students to accomplish their desired educational goals. At KMHSS, we pride ourselves on giving our students a quality education from certified and highly experienced teachers.",
    image: 'images/Banner1.jpg',
    image2: 'images/Banner2.jpg',
    image3: 'images/Banner3.jpg',
    resumePdf: resume
}
