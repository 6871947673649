export const experienceData = [
    {
        _id: 1,        
        experienceHead: 'Principal',
        experienceBody: 'The perfect personification of Leadership with vision, Mr. Sanmugam is a committed and effective leader who understands education and has all the required experience in teaching and administration.',
        experienceImage: 'expimg'
    },
    {
        _id: 2,
        experienceHead: 'Faculties',
        experienceBody: 'A faculty of close to 100 educators have been carefully selected on the basis of their subject expertise and compassion for children.',
        experienceImage: 'expimg'
    },
    {
        _id: 3,
        experienceHead: 'Coachs',
        experienceBody: 'Well experienced coach specialized in each co-curricular activities like Karate, skating, yoga, music, Kathak, dramatics, drawing, batik, debates, quizzing, painting,etc,.',        
        experienceImage: 'expimg'
    },
    {
        _id: 4,
        experienceHead: 'Drivers',
        experienceBody: 'Well experienced drivers to handle school transport vehicles for student\'s safe journey.',        
        experienceImage: 'expimg'
    },
]