export const recentData = [
    { 
        recentName: "Annual Day",
        recentHead: "1st July 2023",
        recentBody: "25th KMHSS Annual Day Celebration",
        recentFoot: "KMHSS, Pollachi",
        recentStatus:1
    },
    {
        recentName: "Board Exam Time Table",
        recentHead: "11th Mar 2023",
        recentBody: "10th and 12th Board Exam Time Table Updated school website result port",
        recentFoot: "KMHSS, Pollachi",
        recentStatus:1
    },
    {
        recentName:"Board Exam Top Student Details",
        recentHead: "5th May 2023",
        recentBody: "10th and 12th Top Student Result Details available on our school website result port",
        recentFoot: "KMHSS, Pollachi",
        recentStatus:1
    }
]