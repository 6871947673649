export const valueData = [
    {
        _id: 1,
        valueHead: 'LEARNING',
        valueBody: 'We believe learning and evolving  is essential to creating change. Not only for the children, teachers and parents, but for our staff, our Board of Directors and our world-changing supporters!',
        valueFoot: 'KMHSS',
        valueImage: 'one'
    },
    {
        _id: 2,
        valueHead: 'EMPOWERMENT',
        valueBody: 'Empowerment is an essential pillar of our work. Our model empowers all stakeholders, including children & youth,  to become education change agents in our society.',
        valueFoot: 'KMHSS',
        valueImage: 'two'
    },
    {
        _id: 3,
        valueHead: 'TRANSPARENCY',
        valueBody: 'We focus on the quality of our work and the honesty in our relationships. We commit to measuring our effectiveness, continually improving our programs.',
        valueFoot: 'KMHSS',
        valueImage: 'three'
    },
    {
        _id: 4,
        valueHead: 'ACCOUNTABILITY',
        valueBody: 'We hold ourselves and all stakeholders accountable. The children deserve nothing less and without accountability, generational poverty is sure to continue.',
        valueFoot: 'KMHSS',
        valueImage: 'four'
    },
    {
        _id: 5,
        valueHead: 'COMMUNITY',
        valueBody: 'We partner with communities to create community. Our model brings together government, community leaders, parents, teachers and global citizens, working together to change the status quo for children.',
        valueFoot: 'KMHSS',
        valueImage: 'seven'
    }
]