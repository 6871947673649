import React, { useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';

import { activityData } from '../../data/activityData';

import './Services.css'
import SingleService from './SingleService/SingleService';

function Services() {

    const { theme } = useContext(ThemeContext);
    return (
        <>
            <div className="services" id="services" style={{ backgroundColor: theme.secondary }}>
                <div className="services-header">
                    <h1 style={{ color: theme.primary }}>Extra Curricular Activities</h1>
                </div>
                <div className="services-body">
                    <p style={{ color: theme.tertiary80 }}>
                        These are some of the extra curriculum activities we provides for our student's growth!
                    </p>
                    <div className="services-bodycontainer">
                        {activityData && activityData.map(activity => (
                            <SingleService
                                key={activity._id}
                                activityBody={activity.activityBody}
                                activityImage={activity.activityImage}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services
