export const contactData = {
    _id: 1,
    contactEmail: "kandasamymhss@gmail.com",
    contactNumber: " 04259 266293, +91 9942264206",
    contactAddress: " Pollachi - Kollengode, Thrissur Road, Ganapathipalayam, Pollachi, Coimbatore, Tamil Nadu, India - 642103",
    contactEmailIcon: "FiAtSign",
    contactNumberIcon: "FiPhone",
    contactAddressIcon: "HiOutlineLocationMarker",
    facebookIcon: "FaFacebook",
    facebookURL: "https://www.facebook.com/",
    facebookStatus: 1,
    twitterIcon: "FaTwitter",
    twitterURL: "https://twitter.com/",
    twitterStatus: 1,
    instaIcon: "FaInstagram",
    instaURL: "https://www.instagram.com/",
    instaStatus: 1,
    linkedIcon: "FaLinkedin",
    linkedURL: "https://www.linkedin.com/in",
    linkedStatus: 1,
    youtubeIcon: "FaYoutube",
    youtubeURL: "https://youtube.com/",
    youtubeStatus: 1,
    contactStatus: 1,
    contactName: "Default Template",
    contactTitle: "Contacts",
}

// export const contactData = {
//     "contactEmail": "kandasamymhss@gmail.com",
//     "contactNumber": " 04259 266293, +91 9942264206",
//     "contactAddress": " Pollachi - Kollengode, Thrissur Road, Ganapathipalayam, Pollachi, Coimbatore, Tamil Nadu, India - 642103",
//     "contactEmailIcon": "FiAtSign",
//     "contactNumberIcon": "FiPhone",
//     "contactAddressIcon": "HiOutlineLocationMarker",
//     "facebookIcon": "FaFacebook",
//     "facebookURL": "https://www.facebook.com/",
//     "facebookStatus": 1,
//     "twitterIcon": "FaTwitter",
//     "twitterURL": "https://twitter.com/",
//     "twitterStatus": 1,
//     "instaIcon": "FaInstagram",
//     "instaURL": "https://www.instagram.com/",
//     "instaStatus": 1,
//     "linkedIcon": "FaLinkedin",
//     "linkedURL": "https://www.linkedin.com/in",
//     "linkedStatus": 1,
//     "youtubeIcon": "FaYoutube",
//     "youtubeURL": "https://youtube.com/",
//     "youtubeStatus": 1,
//     "contactStatus": 1,
//     "_id": "64d914ddc900e731587fae50",
//     "contactName": "Default Template",
//     "contactTitle": "Contacts",
//     "createdDate": "2023-08-13T17:37:33.537Z",
//     "updatedDate": "2023-08-13T17:37:33.537Z",
//     "__v": 0
// }