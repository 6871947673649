export const homeData = {
    "homeHead": "Welcome to",
    "homeBody": "Kandasamy Matriculation Higher Secondary School",
    "homeFoot": "Our school continuously encourages students to accomplish their desired educational goals. At KMHSS, we pride ourselves on giving our students a quality education from certified and highly experienced teachers.",
    "homeImg1": "images/Banner1.jpg",
    "homeImg2": "images/Banner2.jpg",
    "homeImg3": "images/Banner3.jpg",
    "facebookIcon": "FaFacebook",
    "facebookURL": "https://www.facebook.com/",
    "facebookStatus": 1,
    "twitterIcon": "FaTwitter",
    "twitterURL": "https://twitter.com/",
    "twitterStatus": 1,
    "instaIcon": "FaInstagram",
    "instaURL": "https://www.instagram.com/",
    "instaStatus": 1,
    "linkedIcon": "FaLinkedin",
    "linkedURL": "https://www.linkedin.com/in",
    "linkedStatus": 1,
    "youtubeIcon": "FaYoutube",
    "youtubeURL": "https://youtube.com/",
    "youtubeStatus": 1,
    "homeStatus": 1,    
}